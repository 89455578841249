app.controller('loginCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService) {
    $scope.loading = false;
    $scope.username = "";
    $scope.password = "";
    $scope.loggedIn = false;
    
    $scope.login = function(){
        $scope.loading = true;
        likwidGamesService.login($scope.username, $scope.password, function (result) {
            if (result === true) {
                $scope.loading = false;
                $scope.loggedIn = true;
            } else {
                $scope.loading = false;
                console.log('Unable to login: ' + result.error);
            }
        });
    }
    
}]);
