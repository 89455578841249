app.controller('headerCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService) {
    $scope.loading = false;
    $scope.dimmed = false;
    $scope.loginOpen = false;
    $scope.notificationAmt = 0;
    $scope.messageAmt = 0;
    
    $scope.openLogin = function(){
        $scope.toggleDimmer();
        $scope.dimmed = true;
        $scope.loginOpen = true;
    }
    $scope.openSignup = function(){
        $scope.toggleDimmer();
        $scope.dimmed = true;
    }
    $scope.closeLogin = function(){
        $scope.toggleDimmer();
        $scope.dimmed = false;
        $scope.loginOpen = false;
    }
    
    $scope.toggleDimmer = function(){
        $('body').toggleClass('dimmed');
    }
    
    // Run when user logs into the site
    $scope.$on('user-logged-in', function(event, args) {
        $scope.closeLogin();
        $scope.userLoggedIn();
    });
    
    $scope.userLoggedIn = function(){
        $scope.getUserNotificationAmt();
        $scope.getUserMessageAmt();
    }
    
    
    $scope.getUserNotificationAmt = function(){
        likwidGamesService.getUserNotificationAmt().then(function (response) {
            $scope.notificationAmt = response.data.notifications;
        }, function (error) {
            console.log('Unable to get getUserNotificationAmt: ' + error.message);
        });
    }
    $scope.getUserMessageAmt = function(){
        likwidGamesService.getUserMessageAmt().then(function (response) {
            $scope.messageAmt = response.data.messages;
        }, function (error) {
            console.log('Unable to get getUserMessageAmt: ' + error.message);
        });
    }
    
    
    $scope.logout = function(){
        $scope.loading = true;
        likwidGamesService.logout(function (result) {
            $scope.notificationAmt = 0;
            $scope.messageAmt = 0;
            $scope.loading = false;
        });
    }
    
    $scope.isAuthed = function(){
        return likwidGamesService.isAuthed();
    }
    
    $scope.onLoadCheckAuth = function(){
        if($scope.isAuthed()){
            $scope.userLoggedIn();
        }
    }
    
    $scope.onLoadCheckAuth();
}]);
