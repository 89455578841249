app.service('likwidGamesService', ['$http', '$rootScope', function ($http, $rootScope) {
    var urlBase = '/API/';
    
    var _token;
    this.getToken = function(){ return _token; }
    
    
    this.getFeaturedGames = function () {
        return $http.get(urlBase+'get_featured_games.php');
    };
    
    this.getLatestGames = function () {
        return $http.get(urlBase+'get_latest_games.php');
    };
    
    this.getTopGames = function (catID, amt) {
        return $http.post(urlBase+'get_top_games.php', {
            'catID' : catID, 
            'amt' : amt
        });
    };
    
    this.getLatestAvatarItem = function(){
        return $http.get(urlBase+'get_latest_avatar_item.php');
    }
    
    this.getLatestActivity = function(amt){
        return $http.post(urlBase+'get_latest_activity.php', {
            'amt' : amt
        });
    }
    
    this.getLatestPosts = function(amt){
        return $http.post(urlBase+'get_latest_posts.php', {
            'amt' : amt
        });
    }
    
    this.getMonthlyLeaders = function(amt){
        return $http.post(urlBase+'get_monthly_leaders.php', {
            'amt' : amt
        });
    }
    
    this.getLatestWalkthroughs = function(amt){
        return $http.post(urlBase+'get_latest_walkthroughs.php', {
            'amt' : amt
        });
    }
    
    this.getFavorites = function(amt){
        return $http({
            url: urlBase+'get_user_favorites.php',
            skipAuthorization: false,
            data: {'amt':amt},
            method: 'POST'
          });
    }
    
    this.getAvatar = function(){
        return $http({
            url: urlBase+'get_user_avatar.php',
            skipAuthorization: false,
            method: 'GET'
          });
    }
    
    this.getMaleAvatarData = function(){
        return $http.get('/assets/json/avatar/male.json');
    }
    this.getFemaleAvatarData = function(){
        return $http.get('/assets/json/avatar/female.json');
    }
    
    
    this.getUserNotificationAmt = function(){
        return $http({
            url: urlBase+'get_user_notification_amt.php',
            skipAuthorization: false,
            method: 'GET'
          });
    }
    this.getUserMessageAmt = function(){
        return $http({
            url: urlBase+'get_user_message_amt.php',
            skipAuthorization: false,
            method: 'GET'
          });
    }
    
    
    this.login = function(username, password, callback) {
        // Log out if user was already logged in
        if(localStorage.JWT) this.logout();
        
        $http.post(urlBase+'post_login.php', { username: username, password: password })
            .success(function (response) {
                // login successful if there's a token in the response
                if (response.token) {
                    // store username and token in local storage to keep user logged in between page refreshes
                    localStorage.setItem("JWT", response.token );
                    localStorage.setItem("username", username);
                    // add jwt token to auth header for all requests made by the $http service
                    $http.defaults.headers.common.Authorization = 'Bearer ' + response.token;
                    
                    $rootScope.$broadcast('user-logged-in');
                    // execute callback with true to indicate successful login
                    callback(true);
                } else {
                    // execute callback with false to indicate failed login
                    callback(response);
                }
            });
    }
    
    this.logout = function(callback){
        $http.post(urlBase+'post_logout.php')
            .success(function (response) {
                // remove user from local storage and clear http auth header
                delete localStorage.JWT;
                delete localStorage.username;
                $http.defaults.headers.common.Authorization = '';
                // Broadcast to controllers
                $rootScope.$broadcast('user-logged-out');
                // execute callback
                callback(response);
            });
    }
    
    this.getSecureThing = function(){
        return $http({
            url: urlBase+'get_secure_test.php',
            skipAuthorization: false,
            method: 'GET'
          });
    }
    
    
    this.isAuthed = function(){
        return (localStorage.JWT) ? true : false;
    }
    
    
    this.parseJwt = function(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse($window.atob(base64));
    }
    
}]);