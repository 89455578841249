app.controller('latestPostsCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService) {
    $scope.loading = false;
    $scope.posts = [];
    $scope.getLatestPosts = function(){
        $scope.loading = true;
        likwidGamesService.getLatestPosts(5).then(function (response) {
                $scope.posts = response.data.posts;
            console.log(response);
                $scope.loading = false;
            }, function (error) {
                console.log('Unable to load latest posts: ' + error.message);
            });
    }
}]);