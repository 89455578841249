app.controller('latestActivityCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService, $timeout) {
    $scope.loading = false;
    $scope.activityFeed = [];
    
    $scope.getLatestActivityInitialLoad = function() {
        $timeout(function () {
            $scope.getLatestActivity();
        }, 2000);
    }
    
    $scope.getLatestActivity = function(){
        $scope.loading = true;
        likwidGamesService.getLatestActivity(5)
            .then(function (response) {
                $scope.activityFeed = [];
                for(var a=0; a<response.data.activity.length; a++){
                    $scope.activityFeed.push({
                        output:getActivityOutput(response.data.activity[a]),
                        icon:getActivityIcon(response.data.activity[a].type),
                        date:response.data.activity[a].date
                    });
                }
                //$scope.activityFeed = response.data.activity;
                $scope.loading = false;
            }, function (error) {
                console.log('Unable to get latest activity: ' + error.message);
            });
    }
    
    function getActivityOutput(data){
        var returnData = "";
        if(data.type == "1"){
            returnData = "<a href='/user/"+data.firstUser+"'>"+data.firstUser+"</a> and <a href='/user/"+data.secondUser+"'>"+data.secondUser+"</a> are now friends.";
        } else if(data.type == "2"){
            var gameURL = "/games/"+data.secondID+"/"+data.filename;
            returnData = "<a href='/user/"+data.firstUser+"'>"+data.firstUser+"</a> created a new <a href='/walkthroughs/"+data.walkthroughID+"/"+data.filename+"'>walkthrough</a> for <a href='"+gameURL+"'>"+data.gamename+"</a>.";
        } else if(data.type == "3"){
            returnData = "<a href='/user/"+data.firstUser+"'>"+data.firstUser+"</a> is now level "+data.level+"!";
        } else if(data.type == "4"){
            returnData = "New game added: <a href='/games/"+data.secondID+"/"+data.filename+"'>"+data.gamename+"</a>";
        }
        return returnData;
    }
    
    function getActivityIcon(type){
        var iconClass = "";
        if(type == "1"){ // Friends
            iconClass = "heart";
        } else if(type == "2"){ // Walkthrough
            iconClass = "book";
        } else if(type == "3"){ // Level up
            iconClass = "star";
        } else if(type == "4"){ // New Game
            iconClass = "rocket";
        }
        return iconClass;
    }
}]);