(function(){
    'use strict';
    
    app.controller('topGamesCtrl', function($scope, likwidGamesService){
        $scope.gameControllers = [
            'topActionGamesCtrl',
            'topAdventureGamesCtrl',
            'topArcadeGamesCtrl',
            'topPuzzleGamesCtrl',
            'topShootingGamesCtrl',
            'topStrategyGamesCtrl'
        ];
    });
})();