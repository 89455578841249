app.controller('featuredGamesCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService) {
    $scope.loading = false;
    $scope.featuredGames = [];
    $scope.curSlideIndex = 0;
    $scope.curSlideImg = "fsds";
    $scope.getFeaturedGames = function(){
        $scope.loading = true;
        likwidGamesService.getFeaturedGames()
            .then(function (response) {
                $scope.featuredGames = response.data.games;
                $scope.curSlideImg = $scope.featuredGames[0].featuredImg;
                $scope.setBackgroundImage();
                $scope.loading = false;
            }, function (error) {
                console.log('Unable to get featured games: ' + error.message);
            });
    }
    
    $scope.onSlideChange = function(event){
        $scope.curSlideIndex = event.page.index;
        if($scope.curSlideIndex > -1){
            $scope.curSlideImg = $scope.featuredGames[$scope.curSlideIndex].featuredImg;
            $scope.setBackgroundImage();
        }
    }
    
    $scope.setBackgroundImage = function(){
        $('#featured-BG').css('opacity',0);
        $('#featured-BG').css('background-image', 'url(' + $scope.curSlideImg + ')');
        $('#featured-BG').fadeTo(500, 0.4);
    }
}]);