app.controller('latestGamesCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService) {
    $scope.loading = false;
    $scope.latestGames = [];
    $scope.getLatestGames = function(){
        $scope.loading = true;
        likwidGamesService.getLatestGames()
            .then(function (response) {
                $scope.latestGames = response.data.games;
                $scope.loading = false;
            }, function (error) {
                console.log('Unable to get latest games: ' + error.message);
            });
    }
}]);