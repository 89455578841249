app.controller('latestAvatarItemCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService) {
    $scope.loading = false;
    $scope.name = "";
    $scope.reqLevel = 0;
    $scope.positionX = 0;
    $scope.positionY = 0;
    $scope.category = "";
    $scope.sex = "";
    $scope.imgURL = "";
    
    $scope.getLatestItem = function(){
        $scope.loading = true;
        likwidGamesService.getLatestAvatarItem()
            .then(function (response) {
                $scope.name = response.data.items[0].name;
                $scope.reqLevel = response.data.items[0].reqLevel;
                $scope.positionX = response.data.items[0].positionX;
                $scope.positionY = response.data.items[0].positionY;
                $scope.category = response.data.items[0].category;
                $scope.sex = response.data.items[0].sex;
                $scope.imgURL = "avatar/images/"+response.data.items[0].sex+"/"+response.data.items[0].img+".png";
                $scope.loading = false;
            }, function (error) {
                console.log('Unable to get latest avatar item: ' + error.message);
            });
    }
}]);