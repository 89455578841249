$(document).foundation();
setTimeout(function(){
    $(".nano").nanoScroller({ flash: true, preventPageScrolling: true });
}, 0);


if(!document.getElementById('UJVshcuySgqK')){
    console.log("AD BLOCK ON");
    $('.ITkGNeQshzKt').css('display','block');
    $('#multiplayer-games').css('display','none');
} else {
    console.log("SHOW MULTI");
}


//$(document).ready(function(){(adsbygoogle = window.adsbygoogle || []).push({})});

/*
$('.favorites-game-list-carousel').owlCarousel({
    loop:true,
    margin:10,
    stagePadding:10,
    nav:true,
    lazyLoad: true,
    navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
    autoplay:false
});
*/

/*$('#featured-games-html').owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    lazyLoad: true,
    autoplay:false,
    autoplayTimeout:10000,
    autoplayHoverPause:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
});*/

/*$('#multiplayer-games').owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    lazyLoad: false,
    autoplay:false,
    autoplayTimeout:10000,
    autoplayHoverPause:true,
    responsive:{
        0:{
            items:2
        },
        600:{
            items:3
        },
        1000:{
            items:5
        }
    }
});*/
/*$('.scrolling-game-list-carousel').owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    lazyLoad: true,
    autoplay:false,
    autoplayTimeout:10000,
    autoplayHoverPause:true,
    responsive:{
        0:{
            items:2
        },
        600:{
            items:3
        },
        1000:{
            items:5
        }
    }
});*/
/*
$('.top-game-list-carousel').owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    lazyLoad: true,
    autoplay:false,
    autoplayTimeout:10000,
    autoplayHoverPause:true,
    responsive:{
        0:{
            items:2
        },
        600:{
            items:3
        },
        1000:{
            items:4
        }
    }
});
*/


var loadingDirective = [function(){
  return{
      restrict:'E',
      replace:true,
      template:'<div class="loader2 loader-5"></div>',
      link: function(scope, element, attr){
          scope.$watch('loading', function(val){
              if(val){
                  $(element).show();
              } else {
                  $(element).hide();
              }
          });
      }
  }  
}];


var owlCarouselDirective = function() {
	return {
		restrict: 'E',
		transclude: false,
		link: function (scope) {
			scope.initCarousel = function(element) {
			  // provide any default options you want
				var defaultOptions = {
				};
				var customOptions = scope.$eval($(element).attr('data-options'));
				// combine the two options objects
				for(var key in customOptions) {
					defaultOptions[key] = customOptions[key];
                    if (key === 'onChanged') {
                        defaultOptions[key] = scope.onSlideChange;
                    }
                    if (key === 'favoriteArrows') {
                        defaultOptions['navText'] = ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'];
                    }
				}
                
				// init carousel
				$(element).owlCarousel(defaultOptions);
			};
		}
	};
};
var owlCarouselItemDirective = [function() {
	return {
		restrict: 'A',
		transclude: false,
		link: function(scope, element) {
		  // wait for the last item in the ng-repeat then call init
			if(scope.$last) {
				scope.initCarousel(element.parent());
			}
		}
	};
}];

var ngDynamicDirective = ['$compile', '$parse', function ($compile, $parse){
    return {
        scope: {
            name: '=ngDynamicController'
        },
        restrict: 'A',
        terminal: true,
        priority: 100000,
        link: function (scope, elem, attrs){
            elem.attr('ng-controller', scope.name);
            elem.removeAttr('ng-dynamic-controller');
            $compile(elem)(scope);
        }
    };
}];

function run($rootScope, $http, $location, $localStorage) {
    // keep user logged in after page refresh
    if ($localStorage.currentUser) {
        $http.defaults.headers.common.Authorization = 'Bearer ' + $localStorage.currentUser.token;
    }

    // redirect to login page if not logged in and trying to access a restricted page
    $rootScope.$on('$locationChangeStart', function (event, next, current) {
        var publicPages = ['/index.php'];
        var restrictedPage = publicPages.indexOf($location.path()) === -1;
        if (restrictedPage && !$localStorage.currentUser) {
            $location.path('/index.php');
        }
    });
}


var app = angular.module('likwidgamesApp', ['angularMoment','angular-jwt'])
.directive("loading", loadingDirective)
.directive("owlCarousel", owlCarouselDirective)
.directive('owlCarouselItem', owlCarouselItemDirective)
.directive('ngDynamicController', ngDynamicDirective)
.config(function Config($httpProvider, jwtInterceptorProvider) {
  jwtInterceptorProvider.tokenGetter = function() {
    return localStorage.getItem('JWT');
  }
  $httpProvider.interceptors.push('jwtInterceptor');
});

app.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});

