app.controller('topArcadeGamesCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService) {
    $scope.loading = false;
    $scope.games = [];
    $scope.title = "Top Arcade Games";
    $scope.getTopGames = function(){
        $scope.loading = true;
        likwidGamesService.getTopGames(3,5).then(function (response) {
                $scope.games = response.data.games;
                $scope.loading = false;
            }, function (error) {
                console.log('Unable to load top action games: ' + error.message);
            });
    }
}]);