app.controller('latestWalkthroughsCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService) {
    $scope.loading = false;
    $scope.walkthroughs = [];
    $scope.getLatestWalkthroughs = function(){
        $scope.loading = true;
        likwidGamesService.getLatestWalkthroughs(5).then(function (response) {
                $scope.walkthroughs = response.data.walkthroughs;
                $scope.loading = false;
            }, function (error) {
                console.log('Unable to load latest walkthroughs: ' + error.message);
            });
    }
}]);