app.controller('avatarCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService) {
    $scope.loading = false;
        
    $scope.gender = null;
    $scope.skin = null;
    $scope.hair = null;
    $scope.eyes = null;
    $scope.eyebrows = null;
    $scope.nose = null;
    $scope.mouth = null;
    $scope.tops = null;
    $scope.bottoms = null;
    $scope.shoes = null;
    $scope.accHead = null;
    $scope.accBody = null;
    $scope.accArms = null;
    $scope.accLegs = null;
    
    $scope.getAvatar = function(){
        $scope.loading = true;
        
        likwidGamesService.getAvatar()
            .then(function (response) {
                $scope.gender = response.data.avatar.gender;
                var body = response.data.avatar.body;
                $scope.skin = body.skin;
                $scope.hair = body.hair;
                $scope.eyes = body.eyes;
                $scope.eyebrows = body.eyebrows;
                $scope.nose = body.nose;
                $scope.mouth = body.mouth;
                $scope.tops = body.tops;
                $scope.bottoms = body.bottoms;
                $scope.shoes = body.shoes;
                $scope.accHead = response.data.avatar.accHead;
                $scope.accBody = response.data.avatar.accBody;
                $scope.accArms = response.data.avatar.accArms;
                $scope.accLegs = response.data.avatar.accLegs;
                $scope.loading = false;
            }, function (error) {
                console.log('Unable to get avatar: ' + error.message);
            });
    }
    
    $scope.resetAvatar = function(){
        $scope.skin = null;
        $scope.hair = null;
        $scope.eyes = null;
        $scope.eyebrows = null;
        $scope.nose = null;
        $scope.mouth = null;
        $scope.tops = null;
        $scope.bottoms = null;
        $scope.shoes = null;
        $scope.accHead = null;
        $scope.accBody = null;
        $scope.accArms = null;
        $scope.accLegs = null;
    }
    
    // Run when user logs into the site
    $scope.$on('user-logged-in', function(event, args) {
        $scope.getAvatar();
    });
    // Run when user logs out of the site
    $scope.$on('user-logged-out', function(event, args) {
        $scope.resetAvatar();
    });
    
    
    
    $scope.isAuthed = function(){
        return likwidGamesService.isAuthed();
    }
    if($scope.isAuthed()){
        $scope.getAvatar();
    }
}]);
