app.controller('monthlyLeadersCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService) {
    $scope.loading = false;
    $scope.leaders = [];
    $scope.getMonthlyLeaders = function(){
        $scope.loading = true;
        likwidGamesService.getMonthlyLeaders(8).then(function (response) {
                $scope.leaders = response.data.leaders;
                $scope.loading = false;
            }, function (error) {
                console.log('Unable to load monthly leaders: ' + error.message);
            });
    }
}]);