app.controller('favoritesCtrl', ['$scope', 'likwidGamesService', function ($scope, likwidGamesService) {
    $scope.loading = false;
    $scope.favorites = [];
    
    // Run when user logs into the site
    $scope.$on('user-logged-in', function(event, args) {
        $scope.getFavorites();
    });
    // Run when user logs out of the site
    $scope.$on('user-logged-in', function(event, args) {
    });
    
    $scope.getFavorites = function(){
        $scope.loading = true;
        likwidGamesService.getFavorites(10)
            .then(function (response) {
                $scope.favorites = response.data.games;
                //$scope.activityFeed = response.data.activity;
                $scope.loading = false;
                /*$('.favorites-game-list-carousel').owlCarousel({
                    loop:true,
                    margin:10,
                    stagePadding:10,
                    nav:true,
                    lazyLoad: true,
                    navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
                    autoplay:false
                });*/
            }, function (error) {
                console.log('Unable to get latest favorites: ' + error.message);
            });
    }
    
    $scope.isAuthed = function(){
        return likwidGamesService.isAuthed();
    }
    
    //$scope.isAuthed();
    if($scope.isAuthed()){
        $scope.getFavorites();
    }
    
}]);
